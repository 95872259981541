<template>
  <div class="pa-10">
    <logs />
  </div>
</template>
<script>
import logs from "@/components/logs.vue";
export default {
  components: {
    logs,
  },
};
</script>
<style></style>
