var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"admin-left-panel-bg px-8 py-2 white--text"},[_vm._v(" User Activity Detail Log "),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":"","disabled":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"white"},on:{"click":function($event){_vm.dialog = false}}},'v-icon',attrs,false),on),[_vm._v("mdi-close")])]}}])},[_c('span',{staticClass:"text-capitalize logs-tooltip"},[_vm._v("Close")])])],1),_c('br'),_c('v-card-text',{staticClass:"text-break mt-n3"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":[_vm.userDetailLog],"hide-default-footer":true,"fixed-header":"","id":"logs-table"},scopedSlots:_vm._u([{key:"item.request",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ma-2",attrs:{"id":"secret-main"}},[_c('pre',{staticClass:"pa-2",attrs:{"id":"formated-secret"}},[_vm._v(_vm._s(_vm._f("pretty")(item.request)))])])]}},{key:"item.response",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ma-2",attrs:{"id":"secret-main"}},[_c('pre',{staticClass:"pa-2",attrs:{"id":"formated-secret"}},[_vm._v(_vm._s(_vm._f("pretty")(item.response.original)))])])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }