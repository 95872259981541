<template>
  <v-dialog v-model="dialog" width="800" scrollable>
    <v-card>
      <v-card-title class="admin-left-panel-bg px-8 py-2 white--text">
        User Activity Detail Log
        <v-spacer></v-spacer>
        <v-tooltip top :disabled="$vuetify.breakpoint.smAndDown">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="dialog = false"
              >mdi-close</v-icon
            >
          </template>
          <span class="text-capitalize logs-tooltip">Close</span>
        </v-tooltip>
      </v-card-title>

      <br />

      <v-card-text class="text-break mt-n3">
        <v-data-table
          :headers="headers"
          :items="[userDetailLog]"
          :hide-default-footer="true"
          fixed-header
          id="logs-table"
        >
          <template v-slot:[`item.request`]="{ item }">
            <div id="secret-main" class="ma-2">
              <pre
                id="formated-secret"
                class="pa-2"
                >{{ item.request | pretty }}</pre
              >
            </div>
          </template>

          <template v-slot:[`item.response`]="{ item }">
            <div id="secret-main" class="ma-2">
              <pre
                id="formated-secret"
                class="pa-2"
                >{{ item.response.original | pretty }}</pre
              >
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import config from "@/config.js";
// import { request } from 'https';
export default {
  props: ["userDetailLog"],
  components: {},
  data: () => ({
    dialog: true,
    // Headers
    headers: [
      {
        text: "Request",
        align: "start",
        value: "request",
        sortable: false,
        width: "50%"
      },
      { text: "Response", value: "response", align: "top", sortable: false }
    ]
  }),

// -----------------------------------------------watcher---------------------------------------------->
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("closeUserLogsDialog");
      }
    }
  },

  filters: {
    /**
     * This filter is responsible to format request response
     *
     * @return void
     */
    pretty: function(value) {
      let formattedJson = value;
      try {
        formattedJson = JSON.stringify(JSON.parse(value), null, 2);
      } catch (error) {}
      return formattedJson;
    }
  },
};
</script>

<style scoped>
#formated-secret {
  white-space: break-spaces;
}
#secret-main {
  border: 1px solid;
  border-radius: 5px;
}
</style>
<style>
#logs-table .v-data-table__wrapper table tbody tr td {
  vertical-align: top;
}
#logs-table .v-data-table__wrapper table tbody tr:hover {
  background-color: transparent !important;
}
</style>