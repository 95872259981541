var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{staticClass:"p-0 m-0",attrs:{"background-color":"transparent","color":"white","active-class":"admin-primary","show-arrows":"","hide-slider":"","height":"40"},on:{"change":_vm.getTabsData},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.id},[_vm._v(" "+_vm._s(tab.name)+" ")])}),1),_c('v-sheet',{staticClass:"px-8 py-8",class:_vm.$vuetify.breakpoint.xs ? 'd-flex flex-column' : 'd-flex ',attrs:{"color":"white","height":_vm.$vuetify.breakpoint.xs ? '130' : '83',"width":"100%"}},[_c('v-text-field',{staticClass:"mt-n3",staticStyle:{"max-width":"200px"},attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search","placeholder":"Search","single-line":"","outlined":"","dense":"","autofocus":"","color":"admin-primary","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1),(this.logsLoader)?_c('v-skeleton-loader',{attrs:{"loading":this.logsLoader,"type":"table-thead,table-tbody"}}):_c('v-data-table',{staticClass:"shadow-class tableRowBg",attrs:{"headers":_vm.getCurrentTab,"items":_vm.logItems,"hide-default-footer":"","page":_vm.page,"items-per-page":_vm.itemsPerPage,"item-class":_vm.rowClass},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.$vuetify.breakpoint.xs ? 'my-3' : 'my-5'},[_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleString("en-US", _vm.options))+" ")])]}},{key:"item.super_admin_name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate text-capitalize logs-tooltip-width"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.super_admin_name)+" ")])]}}],null,true)},[_c('span',{staticClass:"logs-tooltip"},[_vm._v(" "+_vm._s(item.super_admin_name)+" ")])])]}},{key:"item.impersonate_user_name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate text-capitalize logs-tooltip-width"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.impersonate_user_name)+" ")])]}}],null,true)},[_c('span',{staticClass:"logs-tooltip"},[_vm._v(" "+_vm._s(item.impersonate_user_name)+" ")])])]}},{key:"item.user_name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate text-capitalize logs-tooltip-width"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.user_name)+" ")])]}}],null,true)},[_c('span',{staticClass:"logs-tooltip"},[_vm._v(" "+_vm._s(item.user_name)+" ")])])]}},{key:"item.route",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate logs-tooltip-width"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.route)+" ")])]}}],null,true)},[_c('span',{staticClass:"logs-tooltip"},[_vm._v(" "+_vm._s(item.route)+" ")])])]}},{key:"item.user_agent",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate logs-tooltip-width"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.user_agent)+" ")])]}}],null,true)},[_c('span',{staticClass:"text-capitalize logs-tooltip"},[_vm._v(" "+_vm._s(item.user_agent)+" ")])])]}},{key:"item.company_name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate logs-tooltip-width"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.company_name)+" ")])]}}],null,true)},[_c('span',{staticClass:"text-capitalize logs-tooltip"},[_vm._v(" "+_vm._s(item.company_name)+" ")])])]}},{key:"item.channel_name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate logs-tooltip-width"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.channel_name)+" ")])]}}],null,true)},[_c('span',{staticClass:"text-capitalize logs-tooltip"},[_vm._v(" "+_vm._s(item.channel_name)+" ")])])]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate logs-tooltip-width-100px"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.message)+" ")])]}}],null,true)},[_c('span',{staticClass:"logs-tooltip"},[_vm._v(" "+_vm._s(item.message)+" ")])])]}},{key:"item.user_log_action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.userLogId == item.action_log_id},on:{"click":function($event){return _vm.openUserLogModal(item.action_log_id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v(" View Detail ")])])]}},{key:"item.fcm_log_action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.fcmLogId == item._id},on:{"click":function($event){return _vm.openFcmLogModal(item._id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-note-text ")])],1)]}}],null,true)},[_c('span',[_vm._v(" View Detail ")])])]}}],null,true)}),(_vm.isOpenUserLogsDialog)?_c('LogsModal',{attrs:{"userDetailLog":_vm.userDetailLog},on:{"closeUserLogsDialog":_vm.closeUserLogsDialog}}):_vm._e(),(_vm.isOpenFcmLogsDialog)?_c('FcmModal',{attrs:{"fcmDetailLog":_vm.fcmDetailLog},on:{"closeFcmLogsDialog":_vm.closeFcmLogsDialog}}):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between search-container",class:_vm.$vuetify.breakpoint.xs ? 'flex-column ' : 'py-4 px-8'},[_c('span',{staticClass:"d-flex justify-start",class:_vm.$vuetify.breakpoint.xs ? 'ml-15 ' : ''},[_c('v-subheader',{staticClass:"pb-2"},[_vm._v(" Rows per Page ")]),_c('v-select',{staticClass:"select-per-page",attrs:{"items":_vm.perPage,"dense":"","color":"#432662"},on:{"change":function($event){_vm.resetPagination($event);
          _vm.selected = true;}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"py-1",attrs:{"small":"","color":"admin-primary"}},[_vm._v("mdi-chevron-down")])]},proxy:true}]),model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('v-pagination',{attrs:{"color":"#432662","id":"#pagination","length":_vm.pageCount,"size":"small","next-icon":"mdi-menu-right","prev-icon":"mdi-menu-left","total-visible":9},on:{"input":_vm.readDataAccordingToTab},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('Snackbar',{attrs:{"snackbar":_vm.snackbar,"message":_vm.message,"color":_vm.snackbarColor},on:{"update:snackbar":function($event){_vm.snackbar=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }