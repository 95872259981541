<template>
  <v-dialog v-model="dialog" width="800" scrollable>
    <v-card>
      <v-card-title class="admin-left-panel-bg px-8 py-2 white--text">
        Users Fcm Log
        <v-spacer></v-spacer>
        <v-tooltip top :disabled="$vuetify.breakpoint.smAndDown">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="dialog = false"
              >mdi-close</v-icon
            >
          </template>
          <span class="text-capitalize">Close</span>
        </v-tooltip>
      </v-card-title>

      <br />

      <v-card-text class="text-break mt-n3">
        <br />
        <v-text-field
          v-model="search"
          class="mt-n3"
          style="max-width: 200px"
          prepend-inner-icon="mdi-magnify"
          label="Search"
          placeholder="Search"
          single-line
          outlined
          dense
          autofocus
          color="admin-primary"
          hide-details
        >
        </v-text-field>
        <br />
        <v-data-table
          :headers="headers"
          :items="fcmDetailLog"
          fixed-header
          hide-default-footer
          :search="search"
          :page.sync="fcmPage"
          :items-per-page="fcmItemsPerPage"
          @page-count="fcmPageCount = $event"
          :item-class="rowClass"
        >
          <template v-slot:[`item.user_name`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="text-truncate text-capitalize logs-tooltip-width"
                >
                  {{ item.user_name }}
                </div>
              </template>
              <span class="text-capitalize logs-tooltip">
                {{ item.user_name }}
              </span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.token`]="{ item, index }">
            <v-tooltip bottom v-if="item.token">
              <template v-slot:activator="{ on, attrs }">
                <div class="d-flex">
                  <div
                    :id="item.token + index"
                    v-bind="attrs"
                    v-on="on"
                    class="text-truncate mr-2 logs-tooltip-width logs-tooltip-cursor"
                    @click="copyText(item.token + index)"
                  >
                    {{ item.token }}
                  </div>
                  <v-btn icon x-small @click="copyText(item.token + index)">
                    <v-icon> mdi-content-copy </v-icon>
                  </v-btn>
                </div>
              </template>
              <span class="logs-tooltip"> {{ item.token }} </span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.type`]="{ item }">
            <v-icon color="black">
              {{getIcon(item.type)}}
            </v-icon>
            {{ item.type }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="ma-2 text-truncate text-capitalize"
                  :color="item.status == 'success' ? 'green' : 'red'"
                  text-color="white"
                  v-bind="attrs"
                  v-on="on"
                  style="max-width: 150px"
                >
                  {{ item.status }}
                </v-chip>
              </template>
              <span class="logs-tooltip">
                {{ item.response_data ? item.response_data : 'null' }}
              </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>

      <div
        class="d-flex justify-space-between search-container"
        :class="$vuetify.breakpoint.xs ? 'flex-column ' : 'py-4 px-8'"
      >
        <span
          class="d-flex justify-start"
          :class="$vuetify.breakpoint.xs ? 'ml-15 ' : ''"
        >
          <v-subheader class="pb-2"> Rows per Page </v-subheader>
          <v-select
            :items="fcmPerPage"
            dense
            color="#432662"
            @change="
              setPerPage($event);
              selected = true;
            "
            class="select-per-page"
            style=""
          >
            <template v-slot:append>
              <v-icon small color="admin-primary" class="py-1"
                >mdi-chevron-down</v-icon
              >
            </template>
            <template v-slot:label>
              <span
                style="font-size: 12px"
                class="pl-3"
                :class="selected ? 'd-none' : ''"
                >{{ fcmItemsPerPage }}</span
              >
            </template>
          </v-select>
        </span>
        <!----------------------------------------------- PAGINATION ------------------------------------------------->
        <v-pagination
          color="#432662"
          id="#pagination"
          v-model="fcmPage"
          :length="fcmPageCount"
          size="small"
          next-icon="mdi-menu-right"
          prev-icon="mdi-menu-left"
          total-visible="5"
        ></v-pagination>
      </div>

    </v-card>

    <Snackbar
      :snackbar.sync="snackbar"
      :message="message"
      :color="snackbarColor"
    />
  </v-dialog>
</template>

<script>
import config from "@/config.js";
import Snackbar from "../Snacbar";

export default {
  props: ["fcmDetailLog"],
  components: {
    Snackbar
  },
  data: () => ({
    dialog: true,
    // Headers
    headers: [
      {
        text: "Username",
        align: "start",
        value: "user_name",
        sortable: false
      },
      { text: "Token", value: "token", sortable: false },
      { text: "Type", value: "type", sortable: false },
      { text: "Status", value: "status", align: "center", sortable: false }
    ],

    copyURL: require("@/assets/icons/copyURL.svg"),
    snackbar: false,
    message: "",
    snackbarColor: "error",
    search: "",
    selected: false,

    //PAGINATION VARIABLES
    fcmPage: 1,
    fcmPageCount: 0,
    fcmItemsPerPage: 10,
    fcmPerPage: [10, 25, 50, 100, 250, 500],

  }),

//-----------------------------------------------watcher---------------------------------------------->
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("closeFcmLogsDialog");
      }
    }
  },

//-----------------------------------------------methods---------------------------------------------->
  methods: {
    copyText(id) {
      let input = document.getElementById(id);
      navigator.clipboard.writeText(input.innerText);
      this.snackbar = true;
      this.message = "Copied";
      this.snackbarColor = "success";
    },

    rowClass() {
      return "admin-table-text";
    },

    getIcon(type) {
      if (type == "web") {
        return "mdi-web";
      } else if (type == "android") {
        return "mdi-android";
      } else if (type == "ios") {
        return "mdi-apple";
      }
    },

    setPerPage(perPageEntries) {
      this.fcmItemsPerPage = perPageEntries;
    },

  }
};
</script>