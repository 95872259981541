<!-- 
System: Whistle It
Developer: M Saud Saleem
Date: 17-06-2021 
Organization: Programmer's Force
Purpose: Component for displaying logs data in super admin
-->

<template>
  <div>
    <v-tabs
      background-color="transparent"
      color="white"
      active-class="admin-primary"
      show-arrows
      hide-slider
      class="p-0 m-0"
      height="40"
      v-model="activeTab"
      @change="getTabsData"
    >
      <v-tab v-for="tab of tabs" :key="tab.id">
        {{tab.name}}
      </v-tab>
    </v-tabs>
    <v-sheet
      color="white"
      :height="$vuetify.breakpoint.xs ? '130' : '83'"
      width="100%"
      :class="$vuetify.breakpoint.xs ? 'd-flex flex-column' : 'd-flex '"
      class="px-8 py-8"
    >
      <!---------------------------------------------Search Textfield------------------------------------------------>
      <v-text-field
        v-model="search"
        class="mt-n3"
        style="max-width: 200px"
        prepend-inner-icon="mdi-magnify"
        label="Search"
        placeholder="Search"
        single-line
        outlined
        dense
        autofocus
        color="admin-primary"
        hide-details
      >
      </v-text-field>
      <v-spacer></v-spacer>
    </v-sheet>
    <v-skeleton-loader
      v-if="this.logsLoader"
      :loading="this.logsLoader"
      type="table-thead,table-tbody"
    ></v-skeleton-loader>
    <!------------------------------------------------Data Table------------------------------------------------>
    <v-data-table
      :headers="getCurrentTab"
      :items="logItems"
      class="shadow-class tableRowBg"
      hide-default-footer
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :item-class="rowClass"
      v-else
    >
      <!------------------------------------ slot for different column -------------------------------------->
      <template v-slot:[`item.created_at`]="{ item }">
        <div :class="$vuetify.breakpoint.xs ? 'my-3' : 'my-5'">
          {{ new Date(item.created_at).toLocaleString("en-US", options) }}
        </div>
      </template>

      <template v-slot:[`item.super_admin_name`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="text-truncate text-capitalize logs-tooltip-width"
            >
              {{ item.super_admin_name }}
            </div>
          </template>
          <span class="logs-tooltip"> {{ item.super_admin_name }} </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.impersonate_user_name`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="text-truncate text-capitalize logs-tooltip-width"
            >
              {{ item.impersonate_user_name }}
            </div>
          </template>
          <span class="logs-tooltip"> {{ item.impersonate_user_name }} </span>
        </v-tooltip>
      </template>
      
      <template v-slot:[`item.user_name`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="text-truncate text-capitalize logs-tooltip-width"
            >
              {{ item.user_name }}
            </div>
          </template>
          <span class="logs-tooltip"> {{ item.user_name }} </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.route`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="text-truncate logs-tooltip-width"
            >
              {{ item.route }}
            </div>
          </template>
          <span class="logs-tooltip"> {{ item.route }} </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.user_agent`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="text-truncate logs-tooltip-width"
            >
              {{ item.user_agent }}
            </div>
          </template>
          <span class="text-capitalize logs-tooltip">
            {{ item.user_agent }}
          </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.company_name`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="text-truncate logs-tooltip-width"
            >
              {{ item.company_name }}
            </div>
          </template>
          <span class="text-capitalize logs-tooltip">
            {{ item.company_name }}
          </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.channel_name`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="text-truncate logs-tooltip-width"
            >
              {{ item.channel_name }}
            </div>
          </template>
          <span class="text-capitalize logs-tooltip">
            {{ item.channel_name }}
          </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.message`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="text-truncate logs-tooltip-width-100px"
            >
              {{ item.message }}
            </div>
          </template>
          <span class="logs-tooltip"> {{ item.message }} </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.user_log_action`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              :loading="userLogId == item.action_log_id"
              @click="openUserLogModal(item.action_log_id)"
            >
              <v-icon> mdi-eye </v-icon>
            </v-btn>
          </template>
          <span> View Detail </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.fcm_log_action`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              :loading="fcmLogId == item._id"
              @click="openFcmLogModal(item._id)"
            >
              <v-icon> mdi-note-text </v-icon>
            </v-btn>
          </template>
          <span> View Detail </span>
        </v-tooltip>
      </template>
    </v-data-table>

    <!-- User Activity Log dialog -->
    <LogsModal
      v-if="isOpenUserLogsDialog"
      :userDetailLog="userDetailLog"
      @closeUserLogsDialog="closeUserLogsDialog"
    />

    <!-- Fcm Log dialog -->
    <FcmModal
      v-if="isOpenFcmLogsDialog"
      :fcmDetailLog="fcmDetailLog"
      @closeFcmLogsDialog="closeFcmLogsDialog"
    />

    <div
      class="d-flex justify-space-between search-container"
      :class="$vuetify.breakpoint.xs ? 'flex-column ' : 'py-4 px-8'"
    >
      <span
        class="d-flex justify-start"
        :class="$vuetify.breakpoint.xs ? 'ml-15 ' : ''"
      >
        <v-subheader class="pb-2"> Rows per Page </v-subheader>
        <v-select
          :items="perPage"
          dense
          color="#432662"
          @change="
            resetPagination($event);
            selected = true;
          "
          v-model="itemsPerPage"
          class="select-per-page"
        >
          <template v-slot:append>
            <v-icon small color="admin-primary" class="py-1"
              >mdi-chevron-down</v-icon
            >
          </template>
        </v-select>
      </span>
      <!------------------------------------ PAGINATION -------------------------------------->
      <v-pagination
        color="#432662"
        id="#pagination"
        v-model="page"
        :length="pageCount"
        size="small"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        :total-visible="9"
        @input="readDataAccordingToTab"
      ></v-pagination>
    </div>

    <!-- Snackbar -->
    <Snackbar
      :snackbar.sync="snackbar"
      :message="message"
      :color="snackbarColor"
    />
  </div>
</template>
<!------------------------------------ Script -------------------------------------->
<script>
import { mapActions, mapGetters } from "vuex";
import Snackbar from "@/components/Snacbar";
import LogsModal from "/src/components/Logs/LogsModal";
import FcmModal from "/src/components/Logs/FcmModal";
import axios from "axios";
import config from "@/config.js";
export default {
  components: {
    Snackbar,
    LogsModal,
    FcmModal
  },
  data: () => ({
    signoutErrorSnackText: "Your session has expired !!!",
    signoutErrorSnack: false,
    selected: false,
    search: "",
    message: "",
    snackbarColor: "",
    snackbar: false,
    // Tabs
    activeTab: 0,
    tabs: [
      { id: 0, name: "Impersonate" },
      { id: 1, name: "User Activity" },
      { id: 2, name: "FCM Token" }
    ],
    //options for date formate
    options: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    },
    // Snackbar
    snackbar: false,
    message: "",
    snackbarColor: "error",
    //PAGINATION VARIABLES
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    perPage: [10, 25, 50, 100, 250, 500, 750, 1000],
    // Logs and Logs dialog data
    logItems: [],
    logsLoader: false,
    userDetailLog: [],
    fcmDetailLog: [],
    isOpenUserLogsDialog: false,
    isOpenFcmLogsDialog: false,
    userLogId: null,
    fcmLogId: null,

    searchTimeout: null,
    //---------------------------------------------------Headers------------------------------------------------->
    impersonateHeaders: [
      {
        text: "Logged In Super Admin Name",
        align: "start",
        value: "super_admin_name",
        class: "admin-primary--text tableHeaderBg",
        cellClass: "text-capitalize"
      },
      {
        text: "Logged In Super Admin Email",
        value: "super_admin_email",
        class: "admin-primary--text tableHeaderBg"
      },
      {
        text: "Impersonated User Name",
        value: "impersonate_user_name",
        class: "admin-primary--text tableHeaderBg",
        cellClass: "text-capitalize"
      },
      {
        text: "Impersonated User Email",
        value: "impersonate_user_email",
        class: "admin-primary--text tableHeaderBg"
      },
      {
        text: "Logged In Time",
        value: "created_at",
        class: "admin-primary--text tableHeaderBg"
      }
    ],
    userActivityHeaders: [
      {
        text: "User Name",
        align: "start",
        value: "user_name",
        class: "admin-primary--text tableHeaderBg"
      },
      {
        text: "Route",
        value: "route",
        class: "admin-primary--text tableHeaderBg"
      },
      {
        text: "IP Address",
        value: "ip_address",
        class: "admin-primary--text tableHeaderBg"
      },
      {
        text: "User Agent",
        value: "user_agent",
        class: "admin-primary--text tableHeaderBg"
      },
      {
        text: "Created At",
        value: "created_at",
        class: "admin-primary--text tableHeaderBg"
      },
      {
        text: "Action",
        sortable: false,
        value: "user_log_action",
        class: "admin-primary--text tableHeaderBg"
      }
    ],
    fcmHeaders: [
      {
        text: "Company name",
        align: "start",
        value: "company_name",
        class: "admin-primary--text tableHeaderBg"
      },
      {
        text: "Channel Name",
        value: "channel_name",
        class: "admin-primary--text tableHeaderBg"
      },
      {
        text: "Channel Type",
        value: "channel_type",
        class: "admin-primary--text tableHeaderBg",
        cellClass: "text-capitalize"
      },
      {
        text: "Message Id",
        value: "message_id",
        class: "admin-primary--text tableHeaderBg"
      },
      {
        text: "Message Text",
        value: "message",
        class: "admin-primary--text tableHeaderBg"
      },
      {
        text: "Created At",
        value: "created_at",
        class: "admin-primary--text tableHeaderBg"
      },
      {
        text: "Action",
        sortable: false,
        value: "fcm_log_action",
        class: "admin-primary--text tableHeaderBg"
      }
    ]
  }),
  //---------------------------------------------Computed----------------------------------------------->
  computed: {
    getCurrentTab() {
      if (this.activeTab === 0) {
        return this.impersonateHeaders;
      } else if (this.activeTab === 1) {
        return this.userActivityHeaders;
      } else {
        return this.fcmHeaders;
      }
      return [];
    }
  },

  //---------------------------------------------Watcher----------------------------------------------->
  watch: {
    search(newValue) {
      this.page = 1;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        this.readDataAccordingToTab();
      }, 800);
    }

  },
//-----------------------------------------------methods---------------------------------------------->
  methods: {
    setPerPage(perPageEntries) {
      this.itemsPerPage = perPageEntries;
      this.page = 1;
    },

    resetPagination(e) {
      this.page = 1;
      this.itemsPerPage = e;
      this.readDataAccordingToTab();
    },

    rowClass() {
      return "admin-table-text";
    },

    getTabsData() {
      this.page = 1;
      this.itemsPerPage = 10;
      this.search = "";
      this.readDataAccordingToTab();
    },

    readDataAccordingToTab() {
      if (this.activeTab === 0) {
        this.fetchImpersonate();
      } else if (this.activeTab === 1) {
        this.fetchUserActivity();
      } else {
        this.fetchFcm();
      }
    },

    fetchImpersonate() {
      this.logsLoader = true;
      this.logItems = [];
      let payload = {
        page: this.page,
        per_page: this.itemsPerPage,
        search: this.search
      };
      this.$store
        .dispatch("fetchImpersonateLogs", payload)
        .then(response => {
          this.logsLoader = false;
          let responseData = response.data.data;
          this.logItems = responseData.data;
          this.pageCount = responseData.last_page;
        })
        .catch((error) => {
          this.errorDetector(error);
        });
    },

    fetchUserActivity() {
      this.logsLoader = true;
      this.logItems = [];
      let payload = {
        page: this.page,
        per_page: this.itemsPerPage,
        search: this.search
      };
      this.$store
        .dispatch("fetchUserActivityLogs", payload)
        .then(response => {
          this.logsLoader = false;
          let responseData = response.data;
          this.logItems = responseData.data;
          this.pageCount = responseData.pagination.last_page;
        })
        .catch((error) => {
          this.errorDetector(error);
        });
    },

    fetchFcm() {
      this.logsLoader = true;
      this.logItems = [];
      let payload = {
        page: this.page,
        per_page: this.itemsPerPage,
        search: this.search
      };
      this.$store
        .dispatch("fetchFcmLogs", payload)
        .then(response => {
          this.logsLoader = false;
          let responseData = response.data;
          this.logItems = responseData.data;
          this.pageCount = responseData.pagination.last_page;
        })
        .catch((error) => {
          this.errorDetector(error);
        });
    },

    openUserLogModal(id) {
      this.userLogId = id;
      let requestObj = {
        userLogId: this.userLogId
      };

      this.$store
        .dispatch("fetchDetailLogs", requestObj)
        .then(response => {
          this.userLogId = null;
          this.userDetailLog = response.data.data;
          this.isOpenUserLogsDialog = true;
        })
        .catch(() => {});
    },

    openFcmLogModal(id) {
      this.fcmLogId = id;
      let requestObj = {
        fcmLogId: this.fcmLogId
      };

      this.$store
        .dispatch("fetchFcmDetailLogs", requestObj)
        .then(response => {
          this.fcmLogId = null;
          this.fcmDetailLog = response.data.data.user_details;
          this.isOpenFcmLogsDialog = true;
        })
        .catch(() => {});
    },

    closeUserLogsDialog() {
      this.isOpenUserLogsDialog = false;
    },

    closeFcmLogsDialog() {
      this.isOpenFcmLogsDialog = false;
    },

    errorDetector(error){
      this.snackbar = true;
      this.snackbarColor = "error";
      this.logsLoader = false;
      if (error.response && error.response.data && error.response.data.error) {
          this.message = error.response.data.error;
      }else{
        this.message = 'Something went wrong';
      }
    }
  },

//------------------------------------------------Mounted------------------------------------------------>
  mounted() {
    this.fetchImpersonate();
  }
};
</script>
<!---------------------------------------------------Style------------------------------------------------>
<style scoped>
.tableHeaderBg {
  color: #eeeff7;
}
#pagination .v-pagination .v-pagination__item,
.v-pagination .v-pagination__more,
.v-pagination {
  height: 21px !important;
  min-width: 20px !important;
  font-size: 12px !important;
}
#pagination .v-pagination__navigation .v-icon {
  font-size: 16px !important;
}
#pagination .v-pagination__navigation .v-pagination__item {
  font-size: 16px !important;
  height: 20px;
  width: 20px;
  color: #eeeff7;
}
#pagination .v-pagination__navigation {
  height: 21px !important;
  width: 20px !important;
}
.v-pagination__item .v-pagination__item--active {
  height: 12px !important;
}
.v-pagination__navigation {
  height: 12px !important;
  width: 5px !important;
}
</style>
<style>
.select-per-page {
  box-sizing: border-box;
  height: 27.98px !important;
  max-width: 53px !important;
  border: 1px solid rgba(67, 38, 98, 0.13);
  border-radius: 2px;
  font-size: 12px !important;
  color: rgba(67, 38, 98, 0.13) !important;
}
.search-container {
  background-color: white;
}
.logs-tooltip {
  display: block;
  max-width: 400px !important;
  word-break: break-word;
}
.logs-tooltip-width {
  max-width: 150px;
}
.logs-tooltip-cursor {
  cursor: pointer;
}
.logs-tooltip-width-100px {
  max-width: 100px;
}
</style>
