var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"admin-left-panel-bg px-8 py-2 white--text"},[_vm._v(" Users Fcm Log "),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":"","disabled":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"white"},on:{"click":function($event){_vm.dialog = false}}},'v-icon',attrs,false),on),[_vm._v("mdi-close")])]}}])},[_c('span',{staticClass:"text-capitalize"},[_vm._v("Close")])])],1),_c('br'),_c('v-card-text',{staticClass:"text-break mt-n3"},[_c('br'),_c('v-text-field',{staticClass:"mt-n3",staticStyle:{"max-width":"200px"},attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search","placeholder":"Search","single-line":"","outlined":"","dense":"","autofocus":"","color":"admin-primary","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('br'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.fcmDetailLog,"fixed-header":"","hide-default-footer":"","search":_vm.search,"page":_vm.fcmPage,"items-per-page":_vm.fcmItemsPerPage,"item-class":_vm.rowClass},on:{"update:page":function($event){_vm.fcmPage=$event},"page-count":function($event){_vm.fcmPageCount = $event}},scopedSlots:_vm._u([{key:"item.user_name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate text-capitalize logs-tooltip-width"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.user_name)+" ")])]}}],null,true)},[_c('span',{staticClass:"text-capitalize logs-tooltip"},[_vm._v(" "+_vm._s(item.user_name)+" ")])])]}},{key:"item.token",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.token)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex"},[_c('div',_vm._g(_vm._b({staticClass:"text-truncate mr-2 logs-tooltip-width logs-tooltip-cursor",attrs:{"id":item.token + index},on:{"click":function($event){return _vm.copyText(item.token + index)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.token)+" ")]),_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.copyText(item.token + index)}}},[_c('v-icon',[_vm._v(" mdi-content-copy ")])],1)],1)]}}],null,true)},[_c('span',{staticClass:"logs-tooltip"},[_vm._v(" "+_vm._s(item.token)+" ")])]):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.getIcon(item.type))+" ")]),_vm._v(" "+_vm._s(item.type)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2 text-truncate text-capitalize",staticStyle:{"max-width":"150px"},attrs:{"color":item.status == 'success' ? 'green' : 'red',"text-color":"white"}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)},[_c('span',{staticClass:"logs-tooltip"},[_vm._v(" "+_vm._s(item.response_data ? item.response_data : 'null')+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"d-flex justify-space-between search-container",class:_vm.$vuetify.breakpoint.xs ? 'flex-column ' : 'py-4 px-8'},[_c('span',{staticClass:"d-flex justify-start",class:_vm.$vuetify.breakpoint.xs ? 'ml-15 ' : ''},[_c('v-subheader',{staticClass:"pb-2"},[_vm._v(" Rows per Page ")]),_c('v-select',{staticClass:"select-per-page",attrs:{"items":_vm.fcmPerPage,"dense":"","color":"#432662"},on:{"change":function($event){_vm.setPerPage($event);
            _vm.selected = true;}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"py-1",attrs:{"small":"","color":"admin-primary"}},[_vm._v("mdi-chevron-down")])]},proxy:true},{key:"label",fn:function(){return [_c('span',{staticClass:"pl-3",class:_vm.selected ? 'd-none' : '',staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.fcmItemsPerPage))])]},proxy:true}])})],1),_c('v-pagination',{attrs:{"color":"#432662","id":"#pagination","length":_vm.fcmPageCount,"size":"small","next-icon":"mdi-menu-right","prev-icon":"mdi-menu-left","total-visible":"5"},model:{value:(_vm.fcmPage),callback:function ($$v) {_vm.fcmPage=$$v},expression:"fcmPage"}})],1)],1),_c('Snackbar',{attrs:{"snackbar":_vm.snackbar,"message":_vm.message,"color":_vm.snackbarColor},on:{"update:snackbar":function($event){_vm.snackbar=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }